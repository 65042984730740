* {
    touch-action: manipulation;
}

#root {
    scroll-snap-type: y mandatory;
    -webkit-scroll-snap-type: y mandatory;
    overflow-y: scroll;
    height: 100vh;
}

.DayWeekSelectionPage{
    scroll-snap-align: start;
    height:100vh;
    max-height: 100vh;
    min-height: 100vh;
}

.dayWeekContainer{
    margin: 2vh 4vw;
}

.dayWeekButton {
    font-size: 7vw;
    margin-left: 6vw;
    margin-top: 2vh;
}

.dayWeekButtonSelected {
    background-color: grey;
    font-size: 7vw;
    margin-left: 6vw;
    margin-top: 2vh;
}

.promptHeader{
    margin-top: 4vh;
    text-align: center;
}

.topBar {
    flex: 1;
    /*margin-top: 2vh;*/
}

.bottomBar {
    flex: 1;
    /*margin-bottom: 2vh;*/
}

.exercisePage {
    scroll-snap-align: start;
    max-height: 100vh;
    min-height: 100vh;
    margin-right: 8vw;
    margin-left: 8vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.timer {
    text-align: center;
    font-size: 2vh;
}

.nextSetReminder{
    text-align: center;
    font-size: 2vh;
    min-height: 2vh;
    max-height: 2vh;
}

.mainContent {
    flex: 1;
}

.set {
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 3vh;
}

.adjustmentButtonContainer {
    margin-right: 5vw;
    margin-left: -5vw;
}

.adjustmentButton {
    margin-top: 1vh;
    min-width: 8vw;
    min-height: 4vh;
}

.setDescriptor {
    margin-top: 2vh;
    margin-right: 4vw;
}

.lastWeekDescriptor {
    font-size: 2.75vw;
    margin-top: -2vh;
}

.checkbox {
    width: 6vw;
    height: 6vh;
    margin-right: 6vw;
}

.notes {
    margin-top: 5vh;
}

.previousExerciseName {
    min-height: 2vh;
    font-size: 3vw;
    text-align: center;
}

.exerciseName {
    text-align: center;
    font-size: 5vw;
}

.nextExerciseName {
    min-height: 2vh;
    font-size: 3vw;
    text-align: center;
}
